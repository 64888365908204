import React from "react";
import BannerBg from "assets/images/banner-bg.png";
import BannerBg2 from "assets/images/images-bg.png";
import MobileBannerBg from "assets/images/banner-mob-bg.png";
import MobileBannerBg2 from "assets/images/images-mob-bg.png";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { ScrollIcon } from "assets/icons";
export default function Banner() {
  return (
    <Box
      pt={{ base: "90px", lg: "200px" }}
      bg={{
        base: `url(${MobileBannerBg2}) no-repeat top right,url(${MobileBannerBg}) no-repeat top left`,
        lg: `url(${BannerBg2}) no-repeat top right,url(${BannerBg}) no-repeat top left`,
      }}
      bgSize={{ base: "auto 100%, 100% 90%", lg: "65% 100%, 100% 90%" }}
      minH={{ base: "450px", lg: "900px" }}
    >
      <Box
        maxW={{ base: "290px", lg: "540px" }}
        ml={{ base: "20px", lg: "10vw" }}
        w="100%"
      >
        <Heading variant="primary" fontSize={{ base: "24px", lg: "48px" }}>
          Socializing & P2P Trade with your friends !
        </Heading>
        <Text
          mt={{ lg: "20px" }}
          variant="primary"
          fontSize={{ base: "12px", lg: "25px" }}
        >
          Welcome to our revolutionary platform where Socializing and
          peer-to-peer trade converge!
        </Text>
        <Button mt="30px" rightIcon={<ArrowForwardIcon />}>
          Socialize Now
        </Button>
        <Box mt="60px" pl="60px" display={{ base: "none", lg:"block"}}>
          <ScrollIcon />
        </Box>
      </Box>
    </Box>
  );
}
