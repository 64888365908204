import React from "react";
import "./App.css";
import Banner from "components/banner";
import Footer from "components/footer";
import Header from "components/header";
import Features from "components/features";
import Socialize from "components/socialize";

function App() {
  return (
    <>
      <Header />
     <Banner />
     <Features />
     <Socialize />
     <Footer />
    </>
  );
}

export default App;
