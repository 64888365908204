import React from "react";
import {
  Box,
  Container,
  Flex,
  VStack,
  Heading,
  Image,
  Text,
  HStack,
} from "@chakra-ui/react";
import SocializeImag from "assets/images/socialize.png";
import AppImag from "assets/images/app-img.png";
import QRCode from "assets/images/qr-code.png";
import { GoogleIcon, PlayIcon } from "assets/icons";

export default function Socialize() {
  return (
    <Box as="section" py="80px" id="#socialize">
      <Container maxW="1280px">
        <Heading as="h2" variant="secondary">
          Socializing like never before!
        </Heading>
        <Image mt="60px" mx="auto" maxW="775px" w="100%" src={SocializeImag} />
      </Container>
      <Box bg="#7F1BFF" py={{ base: "40px", md: "142px" }} mt="30px">
        <Container maxW="1280px">
          <Flex
            justify="space-between"
            pb={{ base: "200px", md: "0" }}
            position="relative"
            direction={{ base: "column", md: "row" }}
            align={{ base: "center", md: "unset" }}
            textAlign={{ base: "center", md: "unset" }}
          >
            <Box display={{ base: "contents", md: "block" }}>
              <Text variant="primary">Our mobile app</Text>
              <Heading as="h3" variant="primary" my="20px">
                Trade. Anytime. Anywhere
              </Heading>
              <Text variant="primary" mb={{ base: "30px", md: "none" }}>
                Innovative data encryption protocol safeguards your every trade.
              </Text>
              <HStack mt="30px" gap="24px" order={{ base: "1", md: "unset" }}>
                <Image w="166px" src={QRCode} alt="QRCode" />
                <Box>
                  <Text variant="primary">Scan to Download</Text>
                  <Text variant="primary" as="strong">
                    IOS & Android
                  </Text>
                </Box>
              </HStack>
            </Box>
            <VStack direction={{ base: "row", md: "column" }}>
              <GoogleIcon />
              <PlayIcon />
            </VStack>
            <Image
              src={AppImag}
              w={{ base: "100%", md: "70%" }}
              alt="AppImag"
              position="absolute"
              top={{ base: "unset", md: "-30px" }}
              bottom={{ base: "-70px", md: "unset" }}
              right={{ md: "5%" }}
            />
          </Flex>
        </Container>
      </Box>
    </Box>
  );
}
