const theme = {
  fonts: {
    body: `'Open Sans', sans-serif`,
    heading: `'Open Sans', sans-serif`,
    label: `'Open Sans', sans-serif`,
  },
  colors: {
    primary: {
      50: "#A7DCDB",
      100: "#A7DCDB",
      200: "#8AD0CF",
      300: "#7CCBC9",
      400: "#6DC5C3",
      500: "#54BAB9",
      600: "#46AFAD",
      700: "#40A09F",
      800: "#3A9290",
      900: "#348382",
    },
    text: "#303A42",
    background: "#fff",
    grayBg: "#F3F3F3FC",
    error: "#E84A4A",
    heading: "rgba(48, 58, 66, 0.87)",
    secondaryText: "#A3A3A3",
  },
  components: {
    Text: {
      baseStyle: {
        color: "text",
      },
      variants: {
        primary: {
          color: "white",
        },
      },
    },
    Card: {
      baseStyle: {
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
      },
    },
    Heading: {
      baseStyle: {
        fontSize: "48px",
        fontWeight: "600",
        color: "heading",
      },
      variants: {
        primary: {
          color: "#ffffff",
        },
        secondary: {
          textAlign: "center",
          position: "relative",
          _after: {
            content: `""`,
            display: "block",
            position: "absolute",
            bottom: "-15px",
            left: "50%",
            width: "70px",
            height: "12px",
            transform: "translateX(-50%)",
            background:
              "linear-gradient(169.02deg, #B2EBF2 -58.33%, #D1C4E9 60.57%)",
            borderRadius: "17px",
          },
        },
        dot: {
          position: "relative",
          _before: {
            content: `""`,
            display: "inline-block",
            width: "18px",
            height: "18px",
            background: "#673AB7",
            borderRadius: "50%",
            mr: "15px",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "700",
        fontSize: "18px",
        border: "1px solid white",
      },
      variants: {
        solid: {
          bg: "white",
          color: "#7D17FF",
          boxShadow: "0px 24px 32px rgba(15, 3, 31, 0.2)",
          borderRadius: "100px",
          _hover: {
            bg: "transparent",
            color: "white",
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "background",
        color: "text",
        fontWeight: "400",
      }
    },
  },
};

export default theme;
