import { Box, Container, Flex, Heading, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import feature from "assets/images/feature-1.png";
import feature2 from "assets/images/feature2.png";
import feature3 from "assets/images/feature3.png";
export default function Features() {

  const [isLargerThan992] = useMediaQuery("(min-width: 992px)");


  
  return (
    <Box as="section" id="#features">
      <Container maxW="1280px">
        <Heading as="h2" variant="secondary" fontSize={{ base: "28px", md: "48px"}}>
          Features
        </Heading>
        <Feature
          image={feature}
          title="P2P Trade"
          description="Experience the future of trading with our peer-to-peer (P2P)
      platform. Say goodbye to intermediaries and hello to direct,
      secure, and efficient transactions. Discover the freedom of
      trading directly with fellow users, unlocking endless
      possibilities and maximizing your potential. Join our P2P trade
      revolution today and take control of your trading destiny."
        />
        <Feature
          image={feature2}
          title="Wallet"
          dir={isLargerThan992 ? "rtl" : ""}
          description="Our user-friendly wallet allows you to effortlessly send and receive coins or tokens. Simplify your transactions with a seamless and secure experience. Manage your digital assets with ease and enjoy the convenience of hassle-free sending and receiving. Experience the freedom of seamless coin/token transfers with our EXTSY wallet"
        />
        <Feature
          image={feature3}
          title="All in one platform"
          description="Discover a world of possibilities on our all-in-one platform. Connect, engage, and share with our social media features. Join vibrant groups, stay updated on exciting events, and indulge in captivating content with our watch now feature. Plus, experience the convenience of peer-to-peer trade, where buying and selling is seamless. Elevate your online experience and unlock the full potential of our integrated features today."
        />
      </Container>
    </Box>
  );
}

function Feature({
  image,
  title,
  description,
  dir
}: {
  image: string;
  title: string;
  description: string;
  dir?:string
}) {
  return (
    <Flex mt="30px" align="center" dir={dir} gap="20px" direction={{ base: "column", lg: "row"}} textAlign={{ base: "center" , md: "initial"}}>
      <Image maxW="600px" w="100%" src={image} alt="feature" order={{ base: "1", md: "unset"}}/>
      <Box flex="1">
        <Heading as="h3" variant="dot" sx={{':before': { mr: dir ? 0: '15px', ml: dir ? '15px' : 0}}} fontSize={{ base: "18px",md: "32px"}}>
          {title}
        </Heading>
        <Text mt="30px">{description}</Text>
      </Box>
    </Flex>
  );
}
